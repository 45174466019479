.trBox{
    padding:2vmin;
}
.trcard{
    margin: 1vmin;
    margin-top: 5vmin;
    display: flex;
    flex-direction: column;
}

.tractionarea{
    display: flex !important;
    flex-direction: column;
    flex-grow: 1;
}
.trcontent{
    flex-grow: 1;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
}
.trmedia{
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-transform: uppercase;
    min-height: 15vh;
    width:100%;
}