body { 
  background-color: #ddd; 
  padding: 0;
  margin: 0;
}
#landingPage{
  display:flex;
  flex-direction: column;
  min-height: 100vh; 
  flex-grow: 1;
  overflow: hidden;
    
}
#landingPage header{
  background-color:#fff;
  box-shadow: none;
}
#landingPage .trBox{
  overflow-y: auto;
  padding-bottom: 10vh;
}

.landingCard{
  margin: 1vmin;
  display: flex;
  flex-direction: column;
  max-width: 30vmax;
  overflow:hidden;

}
@media screen and (max-width: 599px) {
  
  .landingMenu{
  border:2px solid rgba(255,255,255,0.5);
  width:80vw;
}
.landingBox{
  justify-content: center;
  align-items: center;
}

}
.landingToolbar{
  display: flex;
  justify-content: center;
}
.landingBox{
    width:100vw;
    display:flex;
    flex-direction: row;
    flex-grow: 1;
    background-color: rgba(100,100,100,0.9);
    

  }

  .landingMenu{
      display:flex;
      flex-direction: column;
      color:white;
      justify-content: space-between;
      cursor: pointer;
      overflow: auto;
      padding:5vh 5vw 5vh 5vw;
      background-color: transparent;
      
  transition: background-color 1s ease;
  }
  .landingMenu:hover{
      
    background-color: rgba(200,19,180,0.9);
  }
  
  
  .landingMenu:nth-child(odd):hover {

    background-color: rgba(19,200,180,0.9);
}


.landingButton{
  border:2px solid rgba(255,255,255,0.5)!important;
}
